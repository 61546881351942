import { closeDialog, dialog, openDialog, popInHashPrefix } from './dialog_popin.js';
import { isPromoEnabled } from './preferences.js';
import {closeMenu} from "./menu.js";

const _container = document.querySelector('main');
if (_container === null) {
	throw new Error('NO_DIALOG_FOUND');
}

const container = _container;
const body = document.body;

window.addEventListener('hashchange', openTarget);
setTimeout(() => openTarget());

let bigheader: null | HTMLElement = null, forscroll: null | HTMLElement = null;
window.addEventListener('scroll', onScroll);

let promoDisplayed = false;

function onScroll() {
	//sticker les éléments en fonction du scroll :
	if (!bigheader) {
		bigheader = document.querySelector(".bigheader");
	}
	if (!forscroll) {
		forscroll = document.querySelector("#forscroll");
	}

	const test = !(isScrolledIntoView(bigheader) || isScrolledIntoView(forscroll));
	if (!promoDisplayed && !isPromoEnabled()) {
		promoDisplayed = true;
	}
	if (!promoDisplayed && test) {
		promoDisplayed = true;
		//simulator de popin autopromo
		const p = Math.floor(Math.random() * 2) + 1;
		//fin du simulator
		setTimeout(() => {
			openDialog('autopromo' + p);
		}, 5000);
	}
	body.classList.toggle("scrolling", test);
}

let categories: null | HTMLElement = null;

document.addEventListener("change", (e) => {
	const radio = (<HTMLElement>e.target).closest<HTMLInputElement>('input[name="select_categorie"]');
	if (!radio) return;
	if (categories === null) {
		categories = document.querySelector(".categories");
	}
	if (categories === null) {
		throw Error("NO_CATEGORIES");
	}
	const cat_active = categories.querySelector('.active');
	if (cat_active !== null) {
		cat_active.classList.remove('active');
	}
	const forRadio = categories.querySelector(`[for="${radio.id}"]`);
	if (forRadio !== null) {
		const forRadioParent = forRadio.parentElement
		if (forRadioParent !== null) {
			forRadioParent.classList.add('active');
			if (!window.location.hash.startsWith(`#${radio.value}_`)) {
				window.location.hash = radio.value ? radio.value : '';
			}
		}
	}
});

function waitPopinClose() {
	return new Promise<void>(resolve => {
		if (!dialog.open) {
			resolve()
			return;
		}

		let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
			resolve();
		}, 1000);

		document.body.addEventListener('popinclose', function onPopinClose() {
			clearTimeout(timer);
			document.body.removeEventListener('popinclose', onPopinClose);
			setTimeout(() => {
				resolve();
			});
		});
	});
}

//Fonction pour ouvrir les details hash
async function openTarget(e?: HashChangeEvent) {
	const hash = location.hash.substring(1);


	let oldHash: string | null = null;
	if (e) {
		try {
			const url = new URL(e.oldURL);
			oldHash = url.hash.substring(1);
		} catch (e) {
			console.error(e);
		}
		console.debug('Hash change', !oldHash ? '(empty)' : oldHash, '=>', !hash ? '(empty)' : hash);
	}


	if (popInHashPrefix.test(hash)) {
		openDialog((hash.startsWith('_') ? '_' : '') + hash.replace(popInHashPrefix, ''))
			.catch(console.error);
		return;
	} else if (oldHash && popInHashPrefix.test(oldHash) && dialog.open) {
		console.debug('popin close');
		const promise = waitPopinClose();
		closeDialog();
		await promise;
	}

	const cat_id = hash.split('_').at(0);
	const radio = container.querySelector<HTMLInputElement>('#radio_' + cat_id);
	if (radio === null) {
		throw new Error(`RADIO_NOT_FOUND #radio_${cat_id}`);
	}
	closeMenu();
	radio.checked = true;
	radio.dispatchEvent(new Event('change', { bubbles: true }));
	radio.focus();

	let targetElement = document.querySelector<HTMLElement | HTMLDetailsElement>(`#${hash}`);
	if (targetElement) {
		if (targetElement.tagName.toLowerCase() === 'details') {
			(<HTMLDetailsElement>targetElement).open = true;
		}

		if (targetElement.classList.contains('subcategory')) {
			bonnePratiqueView('hierarchy');
		}

		const t = getOffsetTop(targetElement) - 60;
		window.scroll({ top: t, left: 0, behavior: 'smooth' });
	}
}

document.body.classList.toggle('share-api', 'share' in navigator);
document.body.classList.toggle('no-share-api', !('share' in navigator));
document.addEventListener("click", (e) => {
	const bpShare = (<HTMLElement>e.target).closest('.bp-share');
	if (!bpShare) return;

	const bp = bpShare.closest(".bp");
	if (!bp) return;

	const share = {
		title: bp.querySelector<HTMLElement>('.bp_num')?.innerText + ' ' + bp.querySelector<HTMLElement>('.bp_title')?.innerText,
		text: bp.querySelector<HTMLElement>('.bp_description')?.innerText,
		url: document.location.pathname + '#' + bp.getAttribute('id')
	};
	navigator.share(share)
		.catch(console.error);
});

const viewModeList = new Set([
	'hierarchy',
	'priority',
]);
function bonnePratiqueCurrentView() {
	for (let item of viewModeList) {
		if (container.classList.contains(item)) {
			return item;
		}
	}

	throw new Error('CURRENT_VIEW_ERROR');
}
function bonnePratiqueView(viewMode: 'hierarchy' | 'priority') {
	for (let item of viewModeList) {
		container.classList.toggle(item, viewMode === item);
	}
}

// Subcategory header
document.addEventListener("click", (e) => {
	const elm = (<HTMLElement>e.target).closest('.bplist header.subcategory');
	if (!elm) return;

	if (bonnePratiqueCurrentView() === 'hierarchy') {
		location.hash = elm.id;
	} else {
		bonnePratiqueView('hierarchy');
		setTimeout(() => {
			location.hash = elm.id;
		})
	}
});

// changement de vue
document.addEventListener("click", (e) => {
	const viewHierarchie = (<HTMLElement>e.target).closest('button#swicth_view');
	if (!viewHierarchie) return;

	bonnePratiqueView(container.classList.contains('hierarchy') ? 'priority' : 'hierarchy');
});

document.addEventListener("click", (e) => {
	const shareLink: HTMLButtonElement | null = (<HTMLElement>e.target).closest('button#share_link');
	if (!shareLink) return;

	// Copy the url inside the text field
	navigator.clipboard.writeText(location.protocol + '//' + location.host + location.pathname)
		.catch(console.error)
		;

	shareLink.disabled = true;
	shareLink.innerHTML = "Lien Copié !";
	setTimeout(() => {
		if (shareLink !== null) {
			shareLink.disabled = false;
			shareLink.innerHTML = "Copier le lien";
		}
	}, 5000)
})

//position
function getOffsetTop(elem: { offsetParent: any; offsetTop: number; }) {
	// Set our distance placeholder
	let distance = 0;
	// Loop up the DOM
	if (elem.offsetParent) {
		do {
			distance += elem.offsetTop;
			elem = elem.offsetParent;
		} while (elem);
	}
	// Return our distance
	return distance < 0 ? 0 : distance;
}

// test de visibilité
export function isScrolledIntoView(el: Element | null) {
	if (el === null) {
		throw new Error('ELEMENT_NOT_FOUND');
	}
	const rect = el.getBoundingClientRect();
	const elemTop = rect.top;
	const elemBottom = rect.bottom;

	// Only completely visible elements return true:
	//var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
	// Partially visible elements return true:
	return elemTop < window.innerHeight && elemBottom >= 0;
}

// palette de couleurs
document.addEventListener("mouseover", (e) => {
	const li: HTMLElement | null = (<HTMLElement>e.target).closest('#palette li');
	const palette = document.querySelector('#palette');
	if (!li) return;

	var liovers = li.dataset.simcolors?.split('/');
	liovers?.forEach(function (simcolor) {
		const liover = palette?.querySelector('[data-colorcode="' + simcolor + '"]');
		liover?.classList.add('similar_over');
	});
});
document.addEventListener("mouseout", (e) => {
	const li: HTMLElement | null = (<HTMLElement>e.target).closest('#palette li');
	const palette = document.querySelector('#palette');
	if (!li) return;

	var liovers = li.dataset.simcolors?.split('/');
	liovers?.forEach(function (simcolor) {
		const liover = palette?.querySelector('[data-colorcode="' + simcolor + '"]');
		liover?.classList.remove('similar_over');
	});
});

type ExtendedWindow = Window & typeof globalThis & {
	_wsq?: Array<any>
}
const w : ExtendedWindow = window;
w._wsq = w._wsq || [];

document.addEventListener("click", (e)=> {
	if(w._wsq !== undefined) {
		const bp: HTMLDetailsElement | null = (<HTMLElement>e.target).closest('.bp');
		if (!bp) return;

		if (!bp.open) {
			w._wsq.push(function () {
				// @ts-ignore
				_setEvent(`0;Dépliage;Bonne_Pratique;${bp.id}`);
				// @ts-ignore
				_wysistat();
			});
		}
	}
})
document.addEventListener("click", (e)=> {
	if(w._wsq !== undefined) {
		const subcat: HTMLElement | null = (<HTMLElement>e.target).closest('.subcategory');
		if (!subcat) return;

		w._wsq.push(function () {
			// @ts-ignore
			_setEvent(`0;Raccourci;Sous-categorie;${subcat.id}`);
			// @ts-ignore
			_wysistat();
		});
	}
})
document.addEventListener("click", (e)=> {
	if(w._wsq !== undefined) {
		const cat: HTMLElement | null = (<HTMLElement>e.target).closest('.category');
		if (!cat) return;

		w._wsq.push(function () {
			// @ts-ignore
			_setEvent(`0;Raccourci;Categorie;${cat.id}`);
			// @ts-ignore
			_wysistat();
		});
	}
})

document.addEventListener("click", (e)=> {
	const el: HTMLElement | null = (<HTMLElement>e.target).closest('[data-popin-resume-toggle]');
	if (!el) return;

	const resume = el.closest('.resumebase64inline,.resumesvginline');
	if (!resume) return;

	resume.classList.toggle('close');
})
